import { Link } from "react-router-dom";
import { Box, Typography, Grid, Divider } from "@mui/material";
import ThaiQR from './../../assets/images/ThaiQR.jpg';
import Bank from './../../assets/images/bank/bank.png';

const MemberDeposit = () => {
    return (
        <Box my={3}>
            <Box sx={
                {
                    // backgroundColor: '#181818',
                    p: 3,
                    borderRadius: 2,
                    minHeight: '55vh',
                }
            }>
                <Typography fontSize={22} align="center">
                    เลือกช่องทางชำระเงิน
                </Typography>
                <Divider sx={{ my: 2, borderColor: 'white' }} />
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Link to={`/depositscb`}>
                                    <Box display={`flex`} alignContent={`center`} alignItems={`center`} justifyContent={`center`} sx={
                                        {
                                            backgroundColor: 'rgba(255, 255, 255, .4)',
                                            borderRadius: 3,
                                            py: 2,
                                            transition: 'all .2s',
                                            '&:hover': {
                                                backgroundColor: 'rgba(255, 255, 255, .5)',
                                            }
                                        }
                                    }>
                                        <Box display={`flex`} alignContent={`center`} alignItems={`center`} justifyContent={`center`}>
                                            <img src={Bank} alt="QRCODE" style={{ width: `auto`, maxHeight: 100, borderRadius: 15 }} />
                                        </Box>
                                        <Box alignContent={`center`} alignItems={`center`} justifyContent={`center`} ml={3}>
                                            <Typography display={`flex`} alignContent={`center`} alignItems={`center`} justifyContent={`center`} fontSize={22} color={`white`}>
                                                บัญชีเว็บไซต์
                                            </Typography>
                                            <Typography display={`flex`} alignContent={`center`} alignItems={`center`} justifyContent={`center`} color={`rgba(255, 255, 255, .8)`}>
                                                ฝากขั้นต่ำ 1 บาท
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Link>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Link to={`/depositpayment`}>
                                    <Box display={`flex`} alignContent={`center`} alignItems={`center`} justifyContent={`center`} sx={
                                        {
                                            backgroundColor: 'rgba(255, 255, 255, .4)',
                                            borderRadius: 3,
                                            py: 2,
                                            transition: 'all .2s',
                                            '&:hover': {
                                                backgroundColor: 'rgba(255, 255, 255, .5)',
                                            }
                                        }
                                    }>
                                        <Box display={`flex`} alignContent={`center`} alignItems={`center`} justifyContent={`center`}>
                                            <img src={ThaiQR} alt="QRCODE" style={{ width: `auto`, maxHeight: 100, borderRadius: 15 }} />
                                        </Box>
                                        <Box alignContent={`center`} alignItems={`center`} justifyContent={`center`} ml={3}>
                                            <Typography display={`flex`} alignContent={`center`} alignItems={`center`} justifyContent={`center`} fontSize={22} color={`white`}>
                                                พร้อมเพย์ QRCODE
                                            </Typography>
                                            <Typography display={`flex`} alignContent={`center`} alignItems={`center`} justifyContent={`center`} color={`rgba(255, 255, 255, .8)`}>
                                                ฝากขั้นต่ำ 100 บาท
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}

export default MemberDeposit;