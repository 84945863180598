import { useRef, FormEvent } from "react";
import { Typography, Paper, Button, CircularProgress, Grid, InputBase } from "@mui/material";
import { ApiService } from "../../services/api.service";
import Cookies from "universal-cookie";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

interface PropsType {
    loading: boolean;
    setLoading: (state: boolean) => void;
    setQrcode: (value: string | null) => void;
    setAmount: (value: string | null) => void;
    setExpire_dt: (value: string | null) => void;
}

const MemberDepositCreatePayGGPayment = ({ loading, setLoading, setQrcode, setAmount, setExpire_dt }: PropsType) => {
    const navigate = useNavigate();
    const cookies = new Cookies(null, {path: '/'});
    const amountRef = useRef<HTMLInputElement>(null);

    const isOnlyDigits = (str: string) => {
        const pattern = /^\d+$/;
        return pattern.test(str);
    }

    const createRequestPay = async (e: FormEvent) => {
        e.preventDefault();
        
        try {
            if(!amountRef.current || amountRef.current.value.trim() === '')
            {
                return;
            }

            const amount = amountRef.current.value.trim();
            if(!isOnlyDigits(amount))
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'กรุณากรอกจำนวนที่ต้องการฝากเป็นตัวเลขเท่านั้น (ไม่ต้องมีทศนิยม)'
                });
            }
            else if(parseInt(amount) < 100)
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'ยอดฝากเงินขั้นต่ำจำเป็นต้องเป็นยอดมากกว่าหรือเท่ากับ 100 บาทเท่านั้นค่ะ'
                });
            }

            setLoading(true);
            const service = new ApiService();
            const getEndpoint = await service.getEndpointPay();
            if(getEndpoint.data.status_code !== 200)
            {
                return Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    text: `สร้างคำร้องฝากเงินไม่สำเร็จ เนื่องจากไม่สามารถรับ Endpoint ได้กรุณาติดต่อแอดมินค่ะ`
                }).then(() => {
                    navigate('/');
                });
            }

            const endpointPath = getEndpoint.data.data.path;
            const endpoint = getEndpoint.data.data.deposit_endpoint;
            const request = await service.requestPay(cookies.get('access_token'), amount, endpointPath, endpoint);
            if(request.data.status_code === 200 && request.data.redirect_url)
            {
                window.location.href = request.data.redirect_url;
            }
            else
            {
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: request.data.message || "เกิดข้อผิดพลาดไม่ทราบสาเหตุ"
                });
            }

            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    return (
        <form name="user_deposit_payment" id="user_deposit_payment" onSubmit={createRequestPay}>
            <Grid container>
                <Grid item xs={12}>
                    <Paper
                        sx={{ p: '0px 0px', display: 'flex', alignItems: 'center', backgroundColor: '#f4ce5c' }}
                    >
                        <Typography sx={{ mx: 3 }}>
                            จำนวนเงิน
                        </Typography>
                        <InputBase
                            sx={{ flex: 1, backgroundColor: 'white', px: 3 }}
                            type="tel"
                            inputRef={amountRef}
                            placeholder="กรอกจำนวนเงินที่ต้องการฝาก"
                            inputProps={{ 'aria-label': 'กรอกจำนวนเงินที่ต้องการฝาก' }}
                            fullWidth
                        />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Button type="submit" fullWidth sx={
                        {
                            mt: 1,
                            backgroundColor: '#f4ce5c',
                            color: 'black',
                            '&:hover': {
                                backgroundColor: '#e2be55',
                                color: 'black',
                            }
                        }
                    } disabled={loading}>
                        <Typography fontSize={14}>
                            {
                                loading ? <CircularProgress size={20} sx={
                                    {
                                        color: 'white'
                                    }
                                } /> : "ดำเนินการต่อ"
                            }
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default MemberDepositCreatePayGGPayment;