import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ApiService } from "../../services/api.service";

import { Box, Typography, Grid } from "@mui/material";
import Cookies from "universal-cookie";
import HeaderHome from "../Swiper/HeaderHome";

import GameList from "./GameList";
import LoadingPage from "../Loading/LoadingPage";

import SlotBg from './../../assets/images/bggames/slot.svg';
import ArcadeBg from './../../assets/images/bggames/arcade.svg';
import KenoBg from './../../assets/images/bggames/keno.svg';
import SportsBg from './../../assets/images/bggames/sports.svg';
import LottoBg from './../../assets/images/bggames/lotto.svg';
import CardBg from './../../assets/images/bggames/card.svg';

import LottoBanner from './../../assets/images/games/lotto/lotto.png';
import SportsBanner from './../../assets/images/games/sports/sports.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import './../Swiper/GameHome.css';
import { Navigation } from 'swiper/modules';

interface GameImageType {
    vertical: string;
    horizontal: string;
    banner: string;
}

interface GameType {
    id: string;
    provider: string;
    gameName: string;
    gameCategory: string;
    gameType: string[];
    image: GameImageType;
    status: string;
    rtp: number;
}


const GameMain = () => {
    const cookies = new Cookies(null, {path: '/'});
    const navigate = useNavigate();
    const [loaded, setLoaded] = useState<boolean>(false);
    const [gameData, setGameData] = useState<GameType[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoaded(true);
        getGameList();
    }, []);

    const getGameList = async () => {
        try {
            const service = new ApiService();
            const getGame = await service.getGameList();

            setGameData(getGame.data.data);
        } catch (err) {
            console.log(err);
        }
    }

    const startLotto = async () => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            if(!cookies.get('access_token'))
            {
                return navigate('/login');
            }

            const service = new ApiService();
            const getRedirectGameUrl = await service.getRedirectLotto(cookies.get('access_token'));

            if(getRedirectGameUrl.data.status_code !== 200)
            {
                return;
            }

            const userAgent = navigator.userAgent.toLowerCase();
            const safari = /safari/.test(userAgent) && !/chrome/.test(userAgent);

            if(safari)
            {
                window.location.href = getRedirectGameUrl.data.url;
            }
            else
            {
                const link = document.createElement('a');
                link.href = getRedirectGameUrl.data.url;
                link.target = '_blank';
                link.click();
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    const createRedirectSports = async () => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            if(!cookies.get('access_token'))
            {
                return navigate('/login');
            }

            const service = new ApiService();
            let getRedirectGameUrl = await service.getRedirectLoginUrl(cookies.get('access_token'));

            const userAgent = navigator.userAgent.toLowerCase();
            const safari = /safari/.test(userAgent) && !/chrome/.test(userAgent);

            const urlObj = new URL(getRedirectGameUrl.data.url);
            const domain = `https://${urlObj.hostname}`;
            const params: { [key: string]: string } = {};
            urlObj.searchParams.forEach((value, key) => {
                params[key] = value;
            });
            const sportsUrl = `${domain}/sports/?uuid=${params.uuid}`;
            if(safari)
            {
                window.location.href = sportsUrl;
            }
            else
            {
                const link = document.createElement('a');
                link.href = sportsUrl;
                link.target = '_blank';
                link.click();
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    return (
        loaded ? <Grid container spacing={2}>
            <Grid item xs={12}>
                <HeaderHome />
            </Grid>
            {
                loading && <LoadingPage />
            }
            {
                gameData.length > 0 ? <>
                    <Grid item xs={12} sx={
                        {
                            backgroundImage: `url('${SlotBg}')`, backgroundPosition: '130% -5%', backgroundRepeat: 'no-repeat', backgroundSize: '55%'
                        }
                    }>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography fontSize={20}>
                                    เกมสล็อต
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <GameList game={gameData.filter((element: GameType) => element.gameCategory === 'SLOT').sort((a: GameType, b: GameType) => {
                                    if (a.provider === "PGS" && a.gameCategory.toLowerCase() === 'slot') return -1;
                                    if (b.provider === "PGS" && b.gameCategory.toLowerCase() === 'slot') return 1;

                                    return 0;
                                })} perpage={null}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={
                        {
                            backgroundImage: `url('${ArcadeBg}')`, backgroundPosition: '130% -5%', backgroundRepeat: 'no-repeat', backgroundSize: '55%'
                        }
                    }>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography fontSize={20}>
                                    คาสิโนไลฟ์สด
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <GameList game={gameData.filter((element: GameType) => element.gameCategory === 'CASINO').sort((a: GameType, b: GameType) => {
                                    if (a.provider === "SEX" && a.gameName.toLowerCase() === 'baccarat') return -1;
                                    if (b.provider === "SEX" && b.gameName.toLowerCase() === 'baccarat') return 1;
                                    if (a.provider === "SAG" && a.gameName.toLowerCase() === 'baccarat') return -1;
                                    if (b.provider === "SAG" && b.gameName.toLowerCase() === 'baccarat') return 1;
                                    if (a.provider === "PTG" && a.gameName.includes("4Point")) return -1;
                                    if (b.provider === "PTG" && b.gameName.includes("4Point")) return 1;
                                    if (a.provider === "PTGC" && a.gameName.includes("Classic")) return -1;
                                    if (b.provider === "PTGC" && b.gameName.includes("Classic")) return 1;
                                    if (a.provider === "AB" && a.gameName.toLowerCase() === 'baccarat') return -1;
                                    if (b.provider === "AB" && b.gameName.toLowerCase() === 'baccarat') return 1;
                                    return 0;
                                })} perpage={null}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sx={
                        {
                            backgroundImage: `url('${SportsBg}')`, backgroundPosition: '200% -30%', backgroundRepeat: 'no-repeat', backgroundSize: '85%'
                        }
                    }>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography fontSize={20}>
                                    เกมกีฬา
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={10}
                                    className="top-10-game-swiper"
                                >
                                    <SwiperSlide>
                                        <Box sx={
                                            {
                                                cursor: 'pointer'   
                                            }
                                        } onClick={createRedirectSports}>
                                            <Box style={{ position: 'relative', textAlign: 'center' }}>
                                                <img
                                                    src={SportsBanner}
                                                    alt={`Sports`}
                                                    style={{ 
                                                        filter: 'none', 
                                                        borderRadius: 10,
                                                        width: `100%`,
                                                        maxHeight: 200, 
                                                        display: 'block' 
                                                    }}
                                                />
                                                <div style={{
                                                    position: 'absolute',
                                                    bottom: '0',
                                                    color: 'white',
                                                    background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .8), rgba(0, 0, 0, 1))',
                                                    transform: 'translate(-50%, 0%)',
                                                    left: '50%',
                                                    padding: '10px',
                                                    borderRadius: '5px',
                                                    maxWidth: '90%',
                                                    minWidth: '50%',
                                                    width: '100%'
                                                }}>
                                                    <Typography fontSize={12} align='center'>
                                                        Sports
                                                    </Typography>
                                                </div>
                                                <div style={{
                                                    display: 'none',
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    color: 'white',
                                                    // fontSize: '20px',
                                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                    padding: '10px',
                                                    borderRadius: '5px'
                                                }}>
                                                    <Typography fontSize={12} align='center'>
                                                        เพิ่มเติม
                                                    </Typography>
                                                </div>
                                            </Box>
                                        </Box>
                                    </SwiperSlide>
                                </Swiper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sx={
                        {
                            backgroundImage: `url('${LottoBg}')`, backgroundPosition: '200% -30%', backgroundRepeat: 'no-repeat', backgroundSize: '85%'
                        }
                    }>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography fontSize={20}>
                                    หวย
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Swiper
                                    slidesPerView={1}
                                    spaceBetween={10}
                                    className="top-10-game-swiper"
                                >
                                    <SwiperSlide>
                                        <Box sx={
                                            {
                                                cursor: 'pointer'   
                                            }
                                        } onClick={() => startLotto()}>
                                            <Box style={{ position: 'relative', textAlign: 'center' }}>
                                                <img
                                                    src={LottoBanner}
                                                    alt={'LOTTO'}
                                                    style={{ 
                                                        filter: 'none', 
                                                        borderRadius: 10,
                                                        width: `100%`,
                                                        maxHeight: 200, 
                                                        display: 'block' 
                                                    }}
                                                />
                                                <div style={{
                                                    position: 'absolute',
                                                    bottom: '0',
                                                    color: 'white',
                                                    background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .8), rgba(0, 0, 0, 1))',
                                                    transform: 'translate(-50%, 0%)',
                                                    left: '50%',
                                                    padding: '10px',
                                                    borderRadius: '5px',
                                                    maxWidth: '90%',
                                                    minWidth: '50%',
                                                    width: '100%'
                                                }}>
                                                    <Typography fontSize={12} align='center'>
                                                        Lotto & Yeekee
                                                    </Typography>
                                                </div>
                                                <div style={{
                                                    display: 'none',
                                                    position: 'absolute',
                                                    top: '50%',
                                                    left: '50%',
                                                    transform: 'translate(-50%, -50%)',
                                                    color: 'white',
                                                    // fontSize: '20px',
                                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                    padding: '10px',
                                                    borderRadius: '5px'
                                                }}>
                                                    <Typography fontSize={12} align='center'>
                                                        เพิ่มเติม
                                                    </Typography>
                                                </div>
                                            </Box>
                                        </Box>
                                    </SwiperSlide>
                                </Swiper>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={
                        {
                            backgroundImage: `url('${ArcadeBg}')`, backgroundPosition: '130% -5%', backgroundRepeat: 'no-repeat', backgroundSize: '55%'
                        }
                    }>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography fontSize={20}>
                                    เกมตู้ ยิงปลา
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <GameList game={gameData.filter((element: GameType) => element.gameCategory === 'FISH')} perpage={null}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={
                        {
                            backgroundImage: `url('${CardBg}')`, backgroundPosition: '130% -5%', backgroundRepeat: 'no-repeat', backgroundSize: '55%'
                        }
                    }>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography fontSize={20}>
                                    เกมไพ่
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <GameList game={gameData.filter((element: GameType) => element.gameCategory === 'CARD')} perpage={null}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sx={
                        {
                            backgroundImage: `url('${KenoBg}')`, backgroundPosition: '200% -30%', backgroundRepeat: 'no-repeat', backgroundSize: '85%'
                        }
                    }>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography fontSize={20}>
                                    คีโน่
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <GameList game={gameData.filter((element: GameType) => element.gameCategory === 'KENO')} perpage={2}/>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={6} sx={
                        {
                            backgroundImage: `url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUyIiBoZWlnaHQ9IjE1MyIgdmlld0JveD0iMCAwIDE1MiAxNTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxlbGxpcHNlIG9wYWNpdHk9IjAuNCIgY3g9Ijc2IiBjeT0iNzYuMDMyMSIgcng9Ijc2IiByeT0iNzYuMDMyMSIgZmlsbD0idXJsKCNwYWludDBfbGluZWFyXzYyM18xNDEpIi8+CjxnIG9wYWNpdHk9IjAuMyI+CjxwYXRoIGQ9Ik04NC45MzQgMzMuNDkwNEw5NS4yMjc3IDE1LjI2ODlWNDYuMTYwNEg0OS45ODA1VjYuOTc0NjFINTQuNTA1MlY0MS44MDY1SDYwLjMxOTVMNzAuMjUxMiAyNS4zNDg0TDg0LjkzNCAzMy40OTA0TTk1LjIyNzcgNi45NzQ2MUw5NS4xNTk4IDcuOTU0MjZMODMuOTE1OSAyNC4zOTA1TDY4LjA3OTQgMTMuNTA1Nkw1OS4wMjk5IDI2LjU2NzVWNi45NzQ2MUg5NS4yMjc3WiIgZmlsbD0id2hpdGUiLz4KPC9nPgo8ZGVmcz4KPGxpbmVhckdyYWRpZW50IGlkPSJwYWludDBfbGluZWFyXzYyM18xNDEiIHgxPSI3NiIgeTE9IjAiIHgyPSI3NiIgeTI9IjE1Mi4wNjQiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj4KPHN0b3Agc3RvcC1jb2xvcj0iI0Q4RkZENSIvPgo8c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiMyM0UxMDQiLz4KPC9saW5lYXJHcmFkaWVudD4KPC9kZWZzPgo8L3N2Zz4K')`, backgroundPosition: '200% -30%', backgroundRepeat: 'no-repeat', backgroundSize: '85%'
                        }
                    }>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography fontSize={20}>
                                    เทรดดิ้ง
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <GameList game={gameData.filter((element: GameType) => element.gameCategory === 'TRADE')} perpage={2}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </> : <LoadingPage />
            }
        </Grid> : <LoadingPage />
    )
}

export default GameMain;