import Cookies from "universal-cookie";
import { memberDataType } from "../../types";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../services/api.service";
import Swal from "sweetalert2";

import { Box, Typography, Paper, Button, CircularProgress, Grid, Divider, InputBase } from "@mui/material";

const bank_list = [
    {
        "bank_abbr": "BBL",
        "bank_code": "002",
        "bank_logo": "https://cdn.goodgame66.com/images/banks-img/bbl.png",
        "bank_name_en": "Bangkok Bank",
        "bank_name_th": "ธนาคารกรุงเทพ"
    },
    {
        "bank_abbr": "KBANK",
        "bank_code": "004",
        "bank_logo": "https://cdn.goodgame66.com/images/banks-img/kbank.png",
        "bank_name_en": "Kasikorn Bank",
        "bank_name_th": "ธนาคารกสิกรไทย"
    },
    {
        "bank_abbr": "KTB",
        "bank_code": "006",
        "bank_logo": "https://cdn.goodgame66.com/images/banks-img/ktb.png",
        "bank_name_en": "Thai Bank",
        "bank_name_th": "ธนาคารกรุงไทย"
    },
    {
        "bank_abbr": "TTB",
        "bank_code": "011",
        "bank_logo": "https://cdn.goodgame66.com/images/banks-img/ttb.png",
        "bank_name_en": "TMBThanachart Bank",
        "bank_name_th": "ธนาคารทหารไทยธนชาต"
    },
    {
        "bank_abbr": "SCB",
        "bank_code": "014",
        "bank_logo": "https://cdn.goodgame66.com/images/banks-img/scb.png",
        "bank_name_en": "The Siam Commercial Bank",
        "bank_name_th": "ธนาคารไทยพาณิชย์"
    },
    {
        "bank_abbr": "BAY",
        "bank_code": "025",
        "bank_logo": "https://cdn.goodgame66.com/images/banks-img/bay.png",
        "bank_name_en": "Bank of Ayudhya Bank",
        "bank_name_th": "ธนาคารกรุงศรีอยุธยา"
    },
    {
        "bank_abbr": "KKP",
        "bank_code": "069",
        "bank_logo": "https://cdn.goodgame66.com/images/banks-img/kkp.png",
        "bank_name_en": "KIATNAKIN PHATRA BANK",
        "bank_name_th": "ธนาคารเกียรตินาคินภัทร"
    },
    {
        "bank_abbr": "CIMBT",
        "bank_code": "022",
        "bank_logo": "https://cdn.goodgame66.com/images/banks-img/cimb.png",
        "bank_name_en": "CIMB Thai Bank",
        "bank_name_th": "ธนาคารซีไอเอ็มบีไทย"
    },
    {
        "bank_abbr": "TISCO",
        "bank_code": "067",
        "bank_logo": "https://cdn.goodgame66.com/images/banks-img/tisco.png",
        "bank_name_en": "TISCO Bank",
        "bank_name_th": "ธนาคารทิสโก้"
    },
    {
        "bank_abbr": "UOBT",
        "bank_code": "024",
        "bank_logo": "https://cdn.goodgame66.com/images/banks-img/uob.png",
        "bank_name_en": "United Chinese Bank",
        "bank_name_th": "ธนาคารยูโอบี"
    },
    {
        "bank_abbr": "LHFG",
        "bank_code": "073",
        "bank_logo": "https://cdn.goodgame66.com/images/banks-img/lhbank.png",
        "bank_name_en": "Land and Houses",
        "bank_name_th": "ธนาคารแลนด์ แอนด์ เฮ้าส์"
    },
    {
        "bank_abbr": "BAAC",
        "bank_code": "034",
        "bank_logo": "https://cdn.goodgame66.com/images/banks-img/baac.png",
        "bank_name_en": "Bank for Agriculture and Agricultural Cooperatives",
        "bank_name_th": "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร"
    },
    {
        "bank_abbr": "GSB",
        "bank_code": "030",
        "bank_logo": "https://cdn.goodgame66.com/images/banks-img/gsb.png",
        "bank_name_en": "Government Savings Bank",
        "bank_name_th": "ธนาคารออมสิน"
    },
    {
        "bank_abbr": "GHB",
        "bank_code": "033",
        "bank_logo": "https://cdn.goodgame66.com/images/banks-img/ghb.png",
        "bank_name_en": "Government Housing Bank",
        "bank_name_th": "ธนาคารอาคารสงเคราะห์"
    },
    // {
    //     "bank_abbr": "TMW",
    //     "bank_code": "099",
    //     "bank_logo": "https://cdn.goodgame66.com/Images/banks-img/turemoney.png",
    //     "bank_name_en": "True Money Wallet",
    //     "bank_name_th": "ทรูมันนี่วอลเล็ท"
    // }
];

const MemberWithdraw = () => {
    const navigate = useNavigate();
    const cookies = new Cookies(null, {path: '/'});
    const [loaded, setLoaded] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [currentCredit, setCurrentCredit] = useState<number | null>(null);
    const amountRef = useRef<HTMLInputElement>(null);

    const isOnlyDigits = (str: string) => {
        const pattern = /^\d+$/;
        return pattern.test(str);
    }

    const requestPayOut = async () => {
        try {
            if(!amountRef.current || amountRef.current.value.trim() === '')
            {
                return;
            }

            const amount = amountRef.current.value.trim();
            if(!isOnlyDigits(amount))
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'กรุณากรอกจำนวนที่ต้องการถอนเป็นตัวเลขเท่านั้น (ไม่ต้องมีทศนิยม)'
                });
            }
            else if(parseFloat(amount) <= 0)
            {
                return Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: 'จำนวนที่ต้องการถอน ต้องมากกว่า 0 บาทค่ะ'
                });
            }

            setLoading(true);
            const service = new ApiService();
            const getEndpoint = await service.getEndpointPay();

            if(getEndpoint.data.status_code !== 200)
            {
                return Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    text: `สร้างคำร้องถอนเงินไม่สำเร็จ เนื่องจากไม่สามารถรับ Endpoint ได้กรุณาติดต่อแอดมินค่ะ`
                }).then(() => {
                    navigate('/');
                });
            }

            const endpointPath = getEndpoint.data.data.path;
            const endpoint = getEndpoint.data.data.withdraw_endpoint;
            const request = await service.requestPayout(cookies.get('access_token'), amount, endpointPath, endpoint);
            if(request.data.status_code === 200)
            {
                Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    text: `สร้างคำร้องถอนเงินจำนวน ${parseFloat(amount).toLocaleString('en-US', {maximumFractionDigits: 0})} เรียบร้อยแล้วค่ะ`
                }).then(() => {
                    navigate('/');
                });
            }
            else
            {
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    html: `สร้างคำร้องถอนเงินไม่สำเร็จ<br/>(ข้อผิดพลาด: ${request.data.message || "ไม่ทราบข้อผิดพลาด"})`
                });
            }

            setLoading(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
        }
    }

    const [memberData, setMemberData] = useState<memberDataType | null>(null);

    useEffect(() => {
        getMemberData();
        getCurrentCredit();
    }, []);

    const getMemberData = async () => {
        try {
            const service = new ApiService();
            const memberData = await service.getMemberData(cookies.get('access_token'));

            if(memberData.data.status_code === 200 && memberData.data.data)
            {
                setMemberData(memberData.data.data);
                setLoaded(true);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const getCurrentCredit = async () => {
        try {
            const service = new ApiService();
            const currentCreditData = (await service.getCurrentCredit(cookies.get('access_token'))).data;

            if(currentCreditData.status_code === 200)
            {
                setCurrentCredit(currentCreditData.current_credit);
                setLoaded(true);
            }
        } catch (err) {
            console.log(err);
        }
    }
    
    return (
        <Box my={3}>
            <Box sx={
                {
                    // backgroundColor: '#181818',
                    p: 3,
                    borderRadius: 2
                }
            }>
                <Typography fontSize={22} align="center">
                    ถอนเงิน
                </Typography>
                <Divider sx={{ my: 2, borderColor: 'white' }} />
                <Grid container>
                    {
                        memberData !== null && loaded ? <>
                            <Grid item xs={12} display={`flex`} justifyContent={`center`} mb={3}>
                                <img src={bank_list[bank_list.findIndex((element) => element.bank_abbr === memberData.bank)].bank_logo} alt="bank_logo" style={{ width: `auto`, maxHeight: 300, borderRadius: 15 }} />
                            </Grid>
                            <Grid item xs={12} display={`flex`} justifyContent={`center`} mb={3}>
                                <Box sx={
                                    {
                                        backgroundColor: '#f4ce5c',
                                        p: 2,
                                        borderRadius: 3,
                                        width: {
                                            xs: `100%`,
                                            sm: `100%`,
                                            md: 300,
                                            lg: 500
                                        },
                                        maxWidth: 500,
                                        color: 'black',
                                        transition: 'all .2s'
                                    }
                                }>
                                    <Typography align="center" fontSize={16} color={`#181818`}>
                                        ยอดเงินที่สามารถถอนได้
                                    </Typography>
                                    <Typography align="center" fontSize={22} color={`#181818`}>
                                        {
                                            currentCredit === null ? "Loading..." : `฿${parseFloat(String(currentCredit)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                                        }
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Paper
                                    component="form"
                                    sx={{ p: '0px 0px', display: 'flex', alignItems: 'center', backgroundColor: '#f4ce5c', mb: .5 }}
                                >
                                    <Typography sx={{ mx: 3, minWidth: 100, display: `flex`, justifyContent: `center` }}>
                                        ธนาคาร
                                    </Typography>
                                    <InputBase
                                        sx={{ flex: 1, backgroundColor: 'white', px: 3 }}
                                        type="text"
                                        value={`${bank_list[bank_list.findIndex((element) => element.bank_abbr === memberData.bank)].bank_name_th}`}
                                        inputProps={{ 'aria-label': 'ชื่อบัญชี' }}
                                        fullWidth
                                        readOnly
                                    />
                                </Paper>
                                <Paper
                                    component="form"
                                    sx={{ p: '0px 0px', display: 'flex', alignItems: 'center', backgroundColor: '#f4ce5c', mb: .5 }}
                                >
                                    <Typography sx={{ mx: 3, minWidth: 100, display: `flex`, justifyContent: `center` }}>
                                        ชื่อบัญชี
                                    </Typography>
                                    <InputBase
                                        sx={{ flex: 1, backgroundColor: 'white', px: 3 }}
                                        type="text"
                                        value={`${memberData.firstname} ${memberData.lastname}`}
                                        inputProps={{ 'aria-label': 'ชื่อบัญชี' }}
                                        fullWidth
                                        readOnly
                                    />
                                </Paper>
                                <Paper
                                    component="form"
                                    sx={{ p: '0px 0px', display: 'flex', alignItems: 'center', backgroundColor: '#f4ce5c', mb: .5 }}
                                >
                                    <Typography sx={{ mx: 3, minWidth: 100, display: `flex`, justifyContent: `center` }}>
                                        เลขบัญชี
                                    </Typography>
                                    <InputBase
                                        sx={{ flex: 1, backgroundColor: 'white', px: 3 }}
                                        type="text"
                                        value={`${memberData.bank_number}`}
                                        inputProps={{ 'aria-label': 'เลขบัญชี' }}
                                        fullWidth
                                        readOnly
                                    />
                                </Paper>
                                <Paper
                                    component="form"
                                    sx={{ p: '0px 0px', display: 'flex', alignItems: 'center', backgroundColor: '#f4ce5c' }}
                                >
                                    <Typography sx={{ mx: 3, minWidth: 100, display: `flex`, justifyContent: `center` }}>
                                        จำนวนเงิน
                                    </Typography>
                                    <InputBase
                                        sx={{ flex: 1, backgroundColor: 'white', px: 3 }}
                                        type="tel"
                                        inputRef={amountRef}
                                        placeholder="กรอกจำนวนเงินที่ต้องการถอน"
                                        inputProps={{ 'aria-label': 'กรอกจำนวนเงินที่ต้องการถอน' }}
                                        fullWidth
                                    />
                                </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <Button fullWidth sx={
                                    {
                                        mt: 1,
                                        backgroundColor: '#f4ce5c',
                                        color: 'black',
                                        '&:hover': {
                                            backgroundColor: '#e2be55',
                                            color: 'black',
                                        }
                                    }
                                } disabled={loading} onClick={requestPayOut}>
                                    <Typography fontSize={14}>
                                        {
                                            loading ? <CircularProgress size={20} sx={
                                                {
                                                    color: 'white'
                                                }
                                            } /> : "ถอนเงิน"
                                        }
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{ my: 2 }} />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <Typography fontSize={14}>
                                    หมายเหตุ
                                </Typography>
                                <ul>
                                    <li>
                                        <Typography fontSize={14}>
                                            ต้องใช้บัญชีที่สมัครกับเว็บฝากเข้ามาเท่านั้น
                                        </Typography>
                                    </li>
                                    <li>
                                        <Typography fontSize={14}>
                                            ยอดฝากขั้นต่ำ 300 บาท
                                        </Typography>
                                    </li>
                                </ul>
                            </Grid> */}
                        </> : <Grid item xs={12} sx={{ minHeight: 450, display: `flex`, justifyContent: `center`, alignContent: `center`, alignItems: `center` }}>
                            <CircularProgress size={24} sx={
                                {
                                    color: '#f4ce5c'
                                }
                            } />
                        </Grid>
                    }
                </Grid>
            </Box>
        </Box>
    )
}

export default MemberWithdraw;