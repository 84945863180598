// import swiper_01 from './../../assets/images/banner/swiper_01.png';
// import swiper_02 from './../../assets/images/banner/swiper_02.png';
// import swiper_03 from './../../assets/images/banner/swiper_03.png';
// import swiper_04 from './../../assets/images/banner/swiper_04.png';

import swiper_01 from './../../assets/images/banner/swiper_01.jpg';
import swiper_02 from './../../assets/images/banner/swiper_02.jpg';
import swiper_03 from './../../assets/images/banner/swiper_03.jpg';
import swiper_04 from './../../assets/images/banner/swiper_04.jpg';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import './HeaderHome.css';
import { Autoplay, Pagination } from 'swiper/modules';

import { useMediaQuery } from 'react-responsive'

const HeaderHome = () => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    });

    return (
        <Swiper
            slidesPerView={isDesktopOrLaptop ? 2 : 1}
            spaceBetween={20}
            pagination={{
                clickable: true,
            }}
            loop={true}
            autoplay={{
                delay: 2500,
                disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination]}
            className="header-swiper"
        >
            <SwiperSlide>
                <img src={swiper_01} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={swiper_02} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={swiper_03} alt="" />
            </SwiperSlide>
            <SwiperSlide>
                <img src={swiper_04} alt="" />
            </SwiperSlide>
        </Swiper>
    )
}

export default HeaderHome;