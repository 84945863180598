import Cookies from "universal-cookie";
import { useState, useRef, useEffect, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../services/api.service";
import Swal from "sweetalert2";

import { Box, Typography, Paper, Button, CircularProgress, Grid, Divider, InputBase } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import ThaiQR from './../../assets/images/ThaiQR.jpg';

import MemberDepositCreatePayGGPayment from "./MemberDepositCreatePayGGPayment";

const MemberDepositGGPayment = () => {
    const navigate = useNavigate();
    const cookies = new Cookies(null, {path: '/'});
    const [loading, setLoading] = useState<boolean>(false);
    const [qrcode, setQrcode] = useState<string | null>(null);
    const [amount, setAmount] = useState<string | null>(null);
    const [expire_dt, setExpire_dt] = useState<string | null>(null);
    const qrcodeRef = useRef<HTMLImageElement>(null);
    const [checkingTransaction, setCheckingTransaction] = useState<boolean>(false);

    const downloadImage = () => {
        const img = qrcodeRef.current;
        if(img)
        {
            const link = document.createElement('a');
            link.href = img.src;
            link.download = 'qrcode.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    const calculateTimeLeft = () => {
        if(typeof(expire_dt) !== 'string')
        {
            return null;
        }

        const now = new Date().getTime();
        const difference = new Date(expire_dt).getTime() - now;
        let timeLeft: { days?: number; hours?: number; minutes?: number; seconds?: number } = {};
        
        if(difference > 0)
        {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
                minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
                seconds: Math.floor((difference % (1000 * 60)) / 1000),
            };
        }
    
        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    const cancelPay = () => {
        localStorage.removeItem('deposit_data');
        setQrcode(null);
        setAmount(null);
        setExpire_dt(null);
    }

    const checkDepositTransactions = async () => {
        const deposit_data: string | null | undefined = localStorage.getItem('deposit_data');
        if(!deposit_data)
        {
            setTimeout(checkDepositTransactions, 10 * 1000);
            return;
        }

        const data = JSON.parse(deposit_data);
        if(checkingTransaction)
        {
            setTimeout(checkDepositTransactions, 10 * 1000);
            return;
        }
        setCheckingTransaction(true);

        try {
            const service = new ApiService();
            const checkTransaction = await service.checkDepositTransaction(cookies.get('access_token'), data.insertId);

            if(checkTransaction.data.payment_status && checkTransaction.data.payment_status === 'success')
            {
                cancelPay();
                Swal.fire({
                    icon: 'success',
                    title: 'แจ้งเตือน',
                    text: `ฝากเงินจำนวน ${parseFloat(data.amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} บาทเรียบร้อยแล้วค่ะ`
                }).then(() => {
                    navigate('/member')
                });
            }
            else if(checkTransaction.data.payment_status && checkTransaction.data.payment_status === 'reject')
            {
                cancelPay();
                Swal.fire({
                    icon: 'error',
                    title: 'แจ้งเตือน',
                    text: `ฝากเงินจำนวน ${parseFloat(data.amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} บาท ไม่สำเร็จค่ะ`
                }).then(() => {
                    navigate('/deposit')
                });
            }
        } catch (err) {
            console.log(err);
        }

        setCheckingTransaction(false);
        setTimeout(checkDepositTransactions, 10 * 1000);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
    
        return () => clearTimeout(timer);
    }, [timeLeft, qrcode]);

    useEffect(() => {
        if(localStorage.getItem('deposit_data'))
        {
            const deposit_data: string | null | undefined = localStorage.getItem('deposit_data');
            if(deposit_data)
            {
                const data = JSON.parse(deposit_data);
                setQrcode(data.qrcode);
                setAmount(data.amount);
                setExpire_dt(data.expire_dt);
            }
        }
    }, []);

    useEffect(() => {
        checkDepositTransactions();
    }, []);
    
    return (
        <Box my={3}>
            <Box sx={
                {
                    // backgroundColor: '#181818',
                    p: 3,
                    borderRadius: 2
                }
            }>
                <Typography fontSize={22} align="center">
                    ฝากเงินด้วย QR Payment
                </Typography>
                <Divider sx={{ my: 2, borderColor: 'white' }} />
                {
                    qrcode === null ? <Grid container>
                        <Grid item xs={12} display={`flex`} justifyContent={`center`} mb={3}>
                            <img src={ThaiQR} alt="thai_qr_logo" style={{ width: `auto`, maxHeight: 300, borderRadius: 15 }} />
                        </Grid>
                        <Grid item xs={12}>
                            <MemberDepositCreatePayGGPayment loading={loading} setLoading={setLoading} setQrcode={setQrcode} setAmount={setAmount} setExpire_dt={setExpire_dt} />
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{ my: 2 }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography fontSize={14}>
                                หมายเหตุ
                            </Typography>
                            <ul>
                                <li>
                                    <Typography fontSize={14}>
                                        ต้องใช้บัญชีที่สมัครกับเว็บฝากเข้ามาเท่านั้น
                                    </Typography>
                                </li>
                                <li>
                                    <Typography fontSize={14}>
                                        ยอดฝากขั้นต่ำ 100 บาท
                                    </Typography>
                                </li>
                            </ul>
                        </Grid>
                    </Grid> : <Grid container>
                        <Grid item xs={12} display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`}>
                            <img src={qrcode} alt="deposit_qrcode" ref={qrcodeRef} style={{ width: `auto`, maxHeight: 300 }} />
                        </Grid>
                        <Grid item xs={12} display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`}>
                            <Button sx={
                                {
                                    mt: 1,
                                    px: 2,
                                    backgroundColor: '#f4ce5c',
                                    color: 'black',
                                    '&:hover': {
                                        backgroundColor: '#e2be55',
                                        color: 'black',
                                    }
                                }
                            } onClick={downloadImage}>
                                <Typography fontSize={14} display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`}>
                                    <DownloadIcon fontSize="small" />&nbsp;บันทึก QRCODE
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{ my: 2 }} />
                        </Grid>
                        <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                            <Typography>
                                ฝากเงินจำนวน:&nbsp;
                            </Typography>
                            <Typography color={`#f4ce5c`}>
                                ฿{
                                    parseFloat(String(amount)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={12} display={`flex`} justifyContent={`center`}>
                            <Typography>
                                กรุณาทำรายการในเวลา&nbsp;
                            </Typography>
                            <Typography color={`#f4ce5c`}>
                                {
                                    typeof(timeLeft?.days) === 'number' && timeLeft?.days > 0 && `${timeLeft?.days} วัน `
                                }
                                {
                                    typeof(timeLeft?.hours) === 'number' && timeLeft?.hours > 0 && `${timeLeft?.hours} ชั่วโมง `
                                }
                                {
                                    typeof(timeLeft?.minutes) === 'number' && timeLeft?.minutes > 0 && `${timeLeft?.minutes} นาที `
                                }
                                {
                                    typeof(timeLeft?.seconds) === 'number' ? `${timeLeft?.seconds} วินาที ` : '0 วินาที'
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Button fullWidth sx={
                                {
                                    mt: 1,
                                    backgroundColor: '#f4ce5c',
                                    color: 'black',
                                    '&:hover': {
                                        backgroundColor: '#e2be55',
                                        color: 'black',
                                    }
                                }
                            } onClick={cancelPay}>
                                <Typography fontSize={14}>
                                    ยกเลิก
                                </Typography>
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={{ my: 2 }} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography fontSize={14}>
                                หมายเหตุ
                            </Typography>
                            <ul>
                                <li>
                                    <Typography fontSize={14}>
                                        หากท่านต้องการเปลี่ยนยอด หรือ ยกเลิกการทำรายการกรุณากดปุ่มยกเลิก
                                    </Typography>
                                </li>
                                <li>
                                    <Typography fontSize={14}>
                                        กรุณาใช้บัญชีที่ท่านสมัครโอนมาเท่านั้น
                                    </Typography>
                                </li>
                            </ul>
                        </Grid>
                    </Grid>
                }
            </Box>
        </Box>
    )
}

export default MemberDepositGGPayment;