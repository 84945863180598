import Cookies from "universal-cookie";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ApiService } from "../../services/api.service";
import Swal from "sweetalert2";
import { depositScbAccountType } from "../../types";

import { Box, Typography, Paper, Button, CircularProgress, Grid, Divider, IconButton } from "@mui/material";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import toast, { Toaster } from 'react-hot-toast';

const MemberDepositScb = () => {
    const navigate = useNavigate();
    const cookies = new Cookies(null, {path: '/'});
    const [loaded, setLoaded] = useState<boolean>(false);
    const [accountDetail, setAccountDetail] = useState<depositScbAccountType | null>(null);

    useEffect(() => {
        getBankAccountActive();
    }, []);

    const getBankAccountActive = async () => {
        try {
            const service = new ApiService();
            const getAccount = await service.getAccountActive();

            if(getAccount.data.status_code !== 200)
            {
                return;
            }

            setAccountDetail(getAccount.data.data);
            setLoaded(true);
        } catch (err) {
            console.log(err);
        }
    }

    const copyToClipboard = (text: string) => {
        navigator.clipboard.writeText(text);
        toast.success('คัดลอกเรียบร้อยแล้ว');
    }
    
    return (
        <Box my={3}>
            <Toaster />
            <Box sx={
                {
                    // backgroundColor: '#181818',
                    p: 3,
                    borderRadius: 2,
                    minHeight: '55vh'
                }
            }>
                <Typography fontSize={22} align="center">
                    ฝากเงินด้วย บัญชีเว็บไซต์
                </Typography>
                <Divider sx={{ my: 2, borderColor: 'white' }} />
                {
                    loaded && accountDetail !== null ? <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography>
                                ฝากเงินเข้าบัญชีนี้ (ระบบจะเติมเข้ากระเป๋าอัตโนมัติ)
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Box width={`100%`} sx={
                                {
                                    backgroundColor: 'transparent',
                                    border: 'solid 1px rgba(255, 255, 255, .4)',
                                    borderRadius: 2
                                }
                            }>
                                <Grid container spacing={.5} sx={
                                    {
                                        p: 1
                                    }
                                }>
                                    <Grid item xs={4} display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`}>
                                        <img
                                            src={"https://cdn.goodgame66.com/images/banks-img/scb.png"}
                                            alt={"SCB_BANK_LOGO"}
                                            style={{ maxHeight: 100 }}
                                        />
                                    </Grid>
                                    <Grid item xs={8}>
                                        <Typography fontSize={16} color={`white`} display={`flex`} alignContent={`center`} alignItems={`center`} justifyContent={`center`}>
                                            ธนาคาร: ไทยพาณิชย์
                                        </Typography>
                                        <Typography fontSize={18} color={`white`} display={`flex`} alignContent={`center`} alignItems={`center`} justifyContent={`center`}>
                                            ชื่อบัญชี: { accountDetail.bank_account_name }
                                        </Typography>
                                        <Divider sx={{ borderColor: 'rgba(255, 255, 255, .4)', my: 1 }} />
                                        <Typography fontSize={16} color={`white`} display={`flex`} alignContent={`center`} alignItems={`center`} justifyContent={`center`}>
                                            เลขบัญชี: { accountDetail.bank_account_number }
                                            <IconButton onClick={() => copyToClipboard(accountDetail.bank_account_number)}>
                                                <ContentPasteIcon sx={{ fontSize: 12, color: 'white' }} />
                                            </IconButton>
                                        </Typography>
                                    </Grid>
                                    
                                </Grid>
                            </Box>
                        </Grid>
                        <Grid item xs={12} mt={3}>
                            <Typography fontSize={14}>
                                คำอธิบายเพิ่มเติม
                            </Typography>
                            <ul>
                                <li>
                                    <Typography fontSize={14}>
                                        กรุณาใช้บัญชีที่สมัครโอนเท่านั้น หากบัญชีไม่ตรงกัน จะไม่สามารถเติมอัตโนมัติได้
                                    </Typography>
                                </li>
                                <li>
                                    <Typography fontSize={14}>
                                        หลังจากโอนเงินแล้ว กรุณารอ 30 วินาที เงินจะเข้าอัตโนมัติ
                                    </Typography>
                                </li>
                                <li>
                                    <Typography fontSize={14}>
                                        หากตรวจสอบแล้วถูกต้องแต่เติมอัตโนมัติไม่ทำงาน กรุณาติดต่อแอดมิน
                                    </Typography>
                                </li>
                            </ul>
                        </Grid>
                    </Grid> : <Box display={`flex`} sx={{ width: `100%`, height: `100%`, minHeight: `100dvh` }}>
                        <Box display={`flex`} justifyContent={`center`} alignContent={`center`} alignItems={`center`} width={`100%`}>
                            <CircularProgress size={30} sx={
                                {
                                    color: '#f4ce5c'
                                }
                            } />
                        </Box>
                    </Box>
                }
            </Box>
        </Box>
    )
}

export default MemberDepositScb;