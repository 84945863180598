import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ApiService } from "../../services/api.service";
import { providersType } from "../../types";

import { Box, Typography, Grid, Button } from "@mui/material";
import Cookies from "universal-cookie";

import LoadingPage from "../Loading/LoadingPage";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import './../Swiper/GameHome.css';
import { Navigation } from 'swiper/modules';

import SlotBanner from './../../assets/images/category/slot.png';
import CasinoBanner from './../../assets/images/category/casino.png';
import FishBanner from './../../assets/images/category/fish.png';
import KenoBanner from './../../assets/images/category/keno.png';
import TradeBanner from './../../assets/images/category/trade.png';
import SportBanner from './../../assets/images/category/sport.png';
import LottoBanner from './../../assets/images/category/lotto.png';

import { useMediaQuery } from 'react-responsive'

interface GameImageType {
    vertical: string;
    horizontal: string;
    banner: string;
}

interface GameType {
    id: string;
    provider: string;
    gameName: string;
    gameCategory: string;
    gameType: string[];
    image: GameImageType;
    status: string;
    rtp: number;
}

const GameMore = () => {
    const cookies = new Cookies(null, {path: '/'});
    const navigate = useNavigate();
    const { getGameCategory } = useParams();
    const [loaded, setLoaded] = useState<boolean>(false);
    const [gameData, setGameData] = useState<GameType[]>([]);
    const [providers, setProviders] = useState<providersType[]>([]);
    const [currentProviders, setCurrentProviders] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    });

    useEffect(() => {
        setLoaded(false);
        getGameList();
    }, [ getGameCategory ]);

    const getGameList = async () => {
        if(!getGameCategory)
        {
            return;
        }

        try {
            const service = new ApiService();
            const getProviders = await service.getProvidersList(getGameCategory);
            const getGame = await service.getGameList();

            const gameData = getGame.data.data.filter((element: GameType) => element.gameCategory.toLowerCase() === getGameCategory?.toLowerCase());
            const providersData = getProviders.data.providers.filter((element: providersType) => {
                return element.provider, gameData.findIndex((element2: GameType) => element2.provider === element.provider) !== -1 ? true : false;
            });

            setProviders(providersData);
            setGameData(gameData);
            setLoaded(true);
        } catch (err) {
            console.log(err);
        }
    }

    const startLotto = async () => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            if(!cookies.get('access_token'))
            {
                return navigate('/login');
            }

            const service = new ApiService();
            const getRedirectGameUrl = await service.getRedirectLotto(cookies.get('access_token'));

            if(getRedirectGameUrl.data.status_code !== 200)
            {
                return;
            }

            const userAgent = navigator.userAgent.toLowerCase();
            const safari = /safari/.test(userAgent) && !/chrome/.test(userAgent);

            if(safari)
            {
                window.location.href = getRedirectGameUrl.data.url;
            }
            else
            {
                const link = document.createElement('a');
                link.href = getRedirectGameUrl.data.url;
                link.target = '_blank';
                link.click();
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    const createRedirectSports = async () => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            if(!cookies.get('access_token'))
            {
                return navigate('/login');
            }

            const service = new ApiService();
            let getRedirectGameUrl = await service.getRedirectLoginUrl(cookies.get('access_token'));

            const userAgent = navigator.userAgent.toLowerCase();
            const safari = /safari/.test(userAgent) && !/chrome/.test(userAgent);

            const urlObj = new URL(getRedirectGameUrl.data.url);
            const domain = `https://${urlObj.hostname}`;
            const params: { [key: string]: string } = {};
            urlObj.searchParams.forEach((value, key) => {
                params[key] = value;
            });
            const sportsUrl = `${domain}/sports/?uuid=${params.uuid}`;
            if(safari)
            {
                window.location.href = sportsUrl;
            }
            else
            {
                const link = document.createElement('a');
                link.href = sportsUrl;
                link.target = '_blank';
                link.click();
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    const createRedirect = async (game_id: string, provider: string, tab: string) => {
        if(loading)
        {
            return;
        }

        setLoading(true);
        try {
            if(!cookies.get('access_token'))
            {
                return navigate('/login');
            }

            const service = new ApiService();
            let getRedirectGameUrl = await service.getRedirectGameUrl(cookies.get('access_token'), game_id, provider, tab);

            if(getRedirectGameUrl.data.status_code !== 200)
            {
                getRedirectGameUrl = await service.getRedirectLoginUrl(cookies.get('access_token'));
            }

            const userAgent = navigator.userAgent.toLowerCase();
            const safari = /safari/.test(userAgent) && !/chrome/.test(userAgent);

            if(safari)
            {
                window.location.href = getRedirectGameUrl.data.url;
            }
            else
            {
                const link = document.createElement('a');
                link.href = getRedirectGameUrl.data.url;
                link.target = '_blank';
                link.click();
            }
        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }

    return (
        loaded ? <Grid container spacing={2}>
            {
                loading && <LoadingPage />
            }
            {
                gameData.length > 0 ? <>
                    <Grid item xs={12}>
                        <Typography fontSize={20}>
                            หมวดหมู่
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Swiper
                            slidesPerView={isDesktopOrLaptop ? 4.5 : 3.5}
                            spaceBetween={10}
                            className="top-10-game-swiper"
                        >
                            <SwiperSlide>
                                <Box sx={
                                    {
                                        cursor: 'pointer'   
                                    }
                                } onClick={() => navigate('/game/slot')}>
                                    <img
                                        src={SlotBanner}
                                        alt={'category-slot'}
                                        style={{ 
                                            width: `100%`,
                                            maxHeight: 200,
                                            display: 'block',
                                        }}
                                    />
                                </Box>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Box sx={
                                    {
                                        cursor: 'pointer'   
                                    }
                                } onClick={() => navigate('/game/casino')}>
                                    <img
                                        src={CasinoBanner}
                                        alt={'category-casino'}
                                        style={{ 
                                            width: `100%`,
                                            maxHeight: 200,
                                            display: 'block',
                                        }}
                                    />
                                </Box>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Box sx={
                                    {
                                        cursor: 'pointer'   
                                    }
                                } onClick={() => navigate('/game/fish')}>
                                    <img
                                        src={FishBanner}
                                        alt={'category-fish'}
                                        style={{ 
                                            width: `100%`,
                                            maxHeight: 200,
                                            display: 'block',
                                        }}
                                    />
                                </Box>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Box sx={
                                    {
                                        cursor: 'pointer'   
                                    }
                                } onClick={() => createRedirectSports()}>
                                    <img
                                        src={SportBanner}
                                        alt={'category-trade'}
                                        style={{ 
                                            width: `100%`,
                                            maxHeight: 200,
                                            display: 'block',
                                        }}
                                    />
                                </Box>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Box sx={
                                    {
                                        cursor: 'pointer'   
                                    }
                                } onClick={() => startLotto()}>
                                    <img
                                        src={LottoBanner}
                                        alt={'category-trade'}
                                        style={{ 
                                            width: `100%`,
                                            maxHeight: 200,
                                            display: 'block',
                                        }}
                                    />
                                </Box>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Box sx={
                                    {
                                        cursor: 'pointer'   
                                    }
                                } onClick={() => navigate('/game/keno')}>
                                    <img
                                        src={KenoBanner}
                                        alt={'category-keno'}
                                        style={{ 
                                            width: `100%`,
                                            maxHeight: 200,
                                            display: 'block',
                                        }}
                                    />
                                </Box>
                            </SwiperSlide>
                            <SwiperSlide>
                                <Box sx={
                                    {
                                        cursor: 'pointer'   
                                    }
                                } onClick={() => navigate('/game/trade')}>
                                    <img
                                        src={TradeBanner}
                                        alt={'category-trade'}
                                        style={{ 
                                            width: `100%`,
                                            maxHeight: 200,
                                            display: 'block',
                                        }}
                                    />
                                </Box>
                            </SwiperSlide>
                        </Swiper>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography fontSize={20}>
                            {
                                getGameCategory?.toLowerCase() === 'casino' ?
                                    "คาสิโนออนไลน์"
                                : getGameCategory?.toLowerCase() === 'fish' ?
                                    'เกมตู้ ยิงปลา'
                                : getGameCategory?.toLowerCase() === 'slot' ?
                                    'เกมสล็อต'
                                : getGameCategory?.toLowerCase() === 'card' ?
                                    'เกมไพ่'
                                : getGameCategory?.toUpperCase()
                            }
                            {
                                providers[currentProviders].provider ? ` ค่าย ${providers[currentProviders].providerName}` : ""
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sm={1.5} md={1.5}>
                        {/* <Swiper
                            slidesPerView={5}
                            spaceBetween={10}
                            className="top-10-game-swiper"
                        >
                            {
                                providers.map((element: providersType, index: number) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <Box sx={
                                                {
                                                    cursor: 'pointer'   
                                                }
                                            } onClick={() => setCurrentProviders(index)}>
                                                <img
                                                    src={element.logoURL}
                                                    alt={element.providerName}
                                                    style={{ 
                                                        borderRadius: 20,
                                                        width: `100%`,
                                                        maxHeight: 200,
                                                        display: 'block',
                                                    }}
                                                />
                                            </Box>
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper> */}
                        <Box sx={{
                            maxHeight: '74dvh',
                            height: '100%`',
                            overflowY: 'scroll',
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                            '-ms-overflow-style': 'none',
                            'scrollbar-width': 'none'
                        }}>
                            {
                                providers.sort((a: providersType, b: providersType) => {
                                    if (a.provider === "PGS") return -1;
                                    if (b.provider === "PGS") return 1;

                                    return 0;
                                }).map((element: providersType, index: number) => {
                                    return (
                                        <Box sx={
                                            {
                                                cursor: 'pointer',
                                                py: .5,
                                            }
                                        } onClick={() => setCurrentProviders(index)} key={index}>
                                            <img
                                                src={element.logoURL}
                                                alt={element.providerName}
                                                style={{ 
                                                    borderRadius: 20,
                                                    width: `100%`,
                                                    maxHeight: 200,
                                                    display: 'block',
                                                }}
                                            />
                                        </Box>
                                    )
                                })
                            }
                        </Box>
                        
                    </Grid>
                    <Grid item xs={10} sm={10.5} md={10.5}>
                        <Box sx={{
                            maxHeight: '74dvh',
                            height: '100%',
                            overflowY: 'scroll',
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                            '-ms-overflow-style': 'none',
                            'scrollbar-width': 'none'
                        }}>
                            <Grid container spacing={2}>
                                {
                                    gameData.filter((element: GameType) => element.provider === providers[currentProviders].provider).map((element: GameType, index: number) => {
                                        return (
                                            <Grid item xs={4} sm={3} md={3} lg={3} key={`${getGameCategory?.toLowerCase()}-${index}`}>
                                                <Box sx={
                                                    {
                                                        cursor: 'pointer'   
                                                    }
                                                } onClick={() => {
                                                    createRedirect(element.id, element.provider, element.gameCategory)
                                                }}>
                                                    <Box style={{ position: 'relative', textAlign: 'center' }}>
                                                        <img
                                                            src={element.image.vertical}
                                                            alt={element.gameName}
                                                            style={{ 
                                                                borderRadius: 10,
                                                                width: `100%`,
                                                                maxHeight: 200, 
                                                                display: 'block' 
                                                            }}
                                                        />
                                                        <div style={{
                                                            position: 'absolute',
                                                            bottom: '0',
                                                            color: 'white',
                                                            background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .8), rgba(0, 0, 0, 1))',
                                                            transform: 'translate(-50%, 0%)',
                                                            left: '50%',
                                                            padding: '10px',
                                                            borderRadius: '5px',
                                                            maxWidth: '87%',
                                                            minWidth: '50%',
                                                            width: '100%'
                                                        }}>
                                                            <Typography fontSize={12} align='center'>
                                                                {
                                                                    element.provider
                                                                }
                                                                &nbsp;
                                                                {
                                                                    element.gameName
                                                                }
                                                            </Typography>
                                                        </div>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                        </Box>
                        
                    </Grid>
                </> : <LoadingPage />
            }
        </Grid> : <LoadingPage />
    )
}

export default GameMore;